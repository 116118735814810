import axios from '@/plugins/axios'
import i18n from '@/i18n/i18n'
import {getItem, setItem, removeItem} from '@/helpers/persistantStorage'
import {roles} from '@/helpers/variables/roles'
import {decrypt} from '@/helpers/cryptoStandards'
import customErrors from '@/store/error'
import router from '@/router'

const state = () => ({
  userInfo: null,
  isTempPasswordExists: null,
  errorCount: 0,
  permissions: null,
  token: null,
  csrfToken: null,
  basicUrl: process.env.VUE_APP_API_URL,
  timeZone: {},
  temporaryData: null,
})

const getters = {
  isAdmin: (state) => state.userInfo?.roles[0] === roles[0].value,
  isManager: (state) => state.userInfo?.roles[0] === roles[1].value,
  isAdvertiser: (state) => state.userInfo?.roles[0] === roles[2].value,
  isAdvertiserPro: (state) => state.userInfo?.roles[0] === roles[3].value,
  isAdvertiserManager: (state) => state.userInfo?.roles[0] === roles[4].value,
  manager: (state) => {
    const {managerEmail = '-', managerTgUsername = '-'} = state.userInfo
    return {
      email: managerEmail,
      tg: managerTgUsername,
    }
  },
}

const mutations = {
  SET_AUTH_INFO(state, data) {
    state.userInfo = data
  },
  SET_TEMP_PASSWORD(state, data) {
    state.isTempPasswordExists = data
  },
  SET_LOCAL_STORAGE(state, {roles, accessToken, csrfToken, refreshToken}) {
    setItem('nt_role', roles[0])
    setItem('nt_token', accessToken)
    setItem('nt_refresh_token', refreshToken)
    state.token = accessToken
    state.csrfToken = csrfToken
  },
  SET_RESET_STATE(state) {
    state.userInfo = null
    state.isTempPasswordExists = false
  },
  SET_ERROR_COUNT(state, isReset = false) {
    state.errorCount = isReset ? 0 : state.errorCount + 1
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = decrypt(payload)
  },
  SET_TIME_ZONE(state, data) {
    state.timeZone = data
  },
  SET_TEMPORARY_DATA(state, data) {
    state.temporaryData = data
  },
}

const actions = {
  async LOGIN({commit, dispatch}, formData) {
    try {
      const res = await axios.post('auth/login', formData)
      if (res.data && res.status === 200) {
        const {
          isTempPasswordExists,
          permissions,
          csrfToken,
          accessToken,
          login,
          currency,
          timeZoneKey,
          agreement,
        } = res.data
        commit('SET_TEMP_PASSWORD', isTempPasswordExists)

        if (!isTempPasswordExists) {
          commit('SET_LOCAL_STORAGE', res.data)
          commit('SET_AUTH_INFO', res.data)
          commit('SET_PERMISSIONS', permissions)
          dispatch('notification/CONNECT_WEBSOCKET', {csrfToken, accessToken, login}, {root: true})
          dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.login'), {root: true})
        } else {
          commit('SET_TEMPORARY_DATA', {currency, timeZoneKey, agreement})
        }
      }
    } catch (err) {
      await customErrors(err, dispatch)
      commit('SET_ERROR_COUNT')
    }
  },
  async CHECK_USER({commit, dispatch}) {
    try {
      const res = await axios.get('auth/current-user')
      if (res.data && res.status === 200) {
        const {permissions, csrfToken, login, roles} = res.data
        const accessToken = getItem('nt_token')
        const refreshToken = getItem('nt_refresh_token')
        commit('SET_AUTH_INFO', res.data)
        commit('SET_PERMISSIONS', permissions)
        commit('SET_LOCAL_STORAGE', {roles, accessToken, csrfToken, refreshToken})
        dispatch('notification/CONNECT_WEBSOCKET', {csrfToken, accessToken, login}, {root: true})
      } else {
        dispatch('LOGOUT')
      }
      return res
    } catch (err) {
      dispatch('LOGOUT')
      await customErrors(err, dispatch, 'SHOW_TOAST_ERROR', 'toast.authError')
      return err
    }
  },
  async UPDATE_TEMP_PASSWORD({commit, dispatch}, formData) {
    try {
      const res = await axios.put('auth/temp-password-agreement-currency', formData)
      if (res?.status === 200) {
        const {permissions, csrfToken, accessToken, login} = res.data
        commit('SET_LOCAL_STORAGE', res.data)
        commit('SET_AUTH_INFO', res.data)
        commit('SET_PERMISSIONS', permissions)
        dispatch('notification/CONNECT_WEBSOCKET', {csrfToken, accessToken, login}, {root: true})
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.login'), {root: true})
        return res
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async VERIFY_PASSWORD_TOKEN({commit, dispatch}, formData) {
    try {
      const res = await axios.post('auth/reset-password/challenge/verify', formData)
      if (res?.status === 200) {
        return res
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async SET_AUTH_DATA_FOR_EMAIL({commit, dispatch}, formData) {
    try {
      const res = await axios.put('auth/reset-password', formData)
      if (res?.status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.successResetPassword'), {root: true})
        return res
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async CHECK_PASSWORD_DATA({commit, dispatch}, formData) {
    try {
      const res = await axios.post('auth/reset-password/verify', formData)
      if (res?.status === 200) {
        const {isTempPasswordExists, login, timeZoneKey, isResetPassword, currency, agreement} = res.data
        commit('SET_TEMP_PASSWORD', isTempPasswordExists)
        commit('SET_TEMPORARY_DATA', {currency, timeZoneKey, agreement, login, isResetPassword})
        return res
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async GET_TIME_ZONE({commit, dispatch}, withEmptyHeaders = false) {
    try {
      const url = 'dictionary/time-zone/read-all'
      const res = withEmptyHeaders
        ? await axios.get(url, {
            headers: {
              Authorization: {},
            },
          })
        : await axios.get(url)

      if (res.status === 200) {
        commit('SET_TIME_ZONE', res.data)
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async LOGOUT({commit, dispatch}) {
    const token = getItem('nt_token')
    if (token) {
      try {
        removeItem('nt_role')
        removeItem('nt_token')
        removeItem('nt_refresh_token')
        commit('SET_AUTH_INFO', null)
        commit('SET_TEMPORARY_DATA', null)
        commit('security/SET_BALANCE', null, {root: true})
        commit('client/SET_ITEMS', [], {root: true})
        dispatch('notification/DISCONNECT_WEBSOCKET', {}, {root: true})
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.logout'), {root: true})
      } catch (err) {
        await customErrors(err, dispatch)
      }
    }
    commit('SET_RESET_STATE')
  },
  async REFRESH_TOKEN({commit, dispatch, state}) {
    try {
      const res = await axios.post('auth/refresh-token', {token: getItem('nt_refresh_token')})
      if (res.data && res.status === 200) {
        const {csrfToken, accessToken} = res.data
        commit('SET_LOCAL_STORAGE', {roles: state.userInfo.roles, ...res.data})
        dispatch(
          'notification/CONNECT_WEBSOCKET',
          {csrfToken, accessToken, login: state.userInfo.login},
          {root: true},
        )
        return accessToken
      }
    } catch (err) {
      dispatch('LOGOUT')
      await customErrors(err, dispatch)
      await router.push({name: 'Login'})
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
