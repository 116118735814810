<template>
  <v-app :class="appClass">
    <component :is="layout" :component="layout">
      <router-view />
    </component>
    <CustomToaster :value="toaster" :message="message" :color="color" />
  </v-app>
</template>

<script>
import {mapState} from 'vuex'
import {LANG} from '@/helpers/variables/language'
import MainLayout from './layouts/MainLayout'
import EmptyLayout from './layouts/EmptyLayout'

export default {
  name: 'App',
  components: {MainLayout, EmptyLayout},
  computed: {
    ...mapState({
      toaster: (state) => state.toaster,
      message: (state) => state.message,
      color: (state) => state.color,
      locale: (state) => state.locale,
    }),
    layout() {
      return `${this.$route.meta.layout || 'empty'}-layout`
    },
    appClass() {
      return this.locale === LANG.RU ? 'montserrat-family' : ''
    },
  },
}
</script>
