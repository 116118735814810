export const httpRegular =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=/]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&=]*)/

// export const passwordRegular = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*_-])[A-Za-z\d@$!%*?&-_]+/

export const passwordRegularPart = {
  lowercaseLetter: /(?=.*[a-z])/,
  uppercaseLetter: /(?=.*[A-Z])/,
  number: /(?=.*\d)/,
  specialCharacter: /(?=.*[#?!@$%^&*_-])/,
}

export const phoneRegular = {
  plus: /^[+]/,
  plusAndNumber: /^\+?\d*$/,
  number: /^\d*$/,
}

export const emailRegular = /^([a-zA-Z0-9_\-\\./]+)@([a-zA-Z0-9_\-\\./]+)\.([a-zA-Z]{2,10})$/
