<template>
  <div id="filepond" :class="disabled ? 'disabled' : ''">
    <file-pond
      ref="pond"
      name="file"
      :accepted-file-types="acceptedFileTypes"
      :label-idle="$t(label)"
      v-bind:allow-multiple="multiple"
      v-bind:files="files"
      :server="server"
      :credits="null"
      :disabled="disabled"
      v-on:processfile="handleProcessFile"
      v-on:processfilerevert="handleProcessFileRevert"
    />
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

import {mapActions, mapState} from 'vuex'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

export default {
  name: 'FileUploader',
  components: {FilePond},
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'banner',
    },
    view: {
      type: String,
      default: '',
    },
    label: {
      type: [String, Object],
      default: '',
    },
    acceptedFileTypes: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isReset: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    files: [],
    errorItems: {
      BANNER_SIZE_ERROR: 'toast.fileError',
      ANIMATION_BANNER_DURATION_ERROR: 'toast.fileAnimationError',
      AUDIO_FILE_PARSE_ERROR: 'toast.fileAudioError',
      VIDEO_FILE_PARSE_ERROR: 'toast.fileVideoError',
      JS_BANNER_FILE_PARSE_ERROR: 'toast.fileJsError',
      HTML5_BANNER_AD_SIZE_TAG_ERROR: 'toast.fileHtmlSizeError',
      HTML5_BANNER_DURATION_TAG_ERROR: 'toast.fileHtmlDurationError',
      HTML5_BANNER_FILL_CLICK_TAG_ERROR: 'toast.fileHtmlClickError',
      FILE_SIZE_ERROR: 'toast.fileSizeError',
      FILE_NOT_FOUND: 'toast.fileNotFound',
    },
  }),
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      basicUrl: (state) => state.auth.basicUrl,
    }),
    server() {
      return {
        headers: {
          Authorization: this.token,
        },
        process: {
          url: this.id
            ? `${this.basicUrl}/file/creative/${this.type}/upload/${this.id}`
            : `${this.basicUrl}/file/creative/${this.type}/upload`,
          onerror: (err) => JSON.parse(err),
        },
        revert: {
          url: `${this.basicUrl}/file/creative/${this.type}/delete/${this.label}`,
          onerror: (resp) => {
            const error = JSON.parse(resp)
            if (error?.code) {
              const {code} = error
              const errorText = this.errorItems[code] ?? 'toast.error'
              this.showToastError(this.$i18n.t(errorText))
            }
          },
        },
      }
    },
  },
  watch: {
    isReset(val) {
      if (val) {
        this.files = []
        this.$refs.pond.removeFile()
      }
    },
  },
  methods: {
    ...mapActions({
      showToastError: 'SHOW_TOAST_ERROR',
    }),
    handleProcessFile(error, file) {
      if (error?.body.code) {
        const {
          body: {code},
        } = error
        const errorText = this.errorItems[code] ?? 'toast.error'
        this.showToastError(this.$i18n.t(errorText))
        return
      }
      this.$emit('uploaded', JSON.parse(file.serverId), this.view)
    },
    handleProcessFileRevert() {
      this.$emit('uploaded', {}, this.view)
    },
  },
}
</script>
<style lang="scss">
.filepond--wrapper {
  .filepond--root {
    margin-bottom: 0;
  }

  .filepond--panel-root {
    background-color: transparent;
  }

  .filepond--drop-label {
    background: var(--v-mainBg-base);
    border-radius: 4px;
    transition: 0.3s;
    border: 1px solid transparent;
  }
}

.theme--light .filepond--wrapper {
  .filepond--drop-label {
    border-color: rgba(0, 0, 0, 0.32);
    color: rgba(0, 0, 0, 0.6);
  }
}

.theme--dark .filepond--wrapper {
  .filepond--drop-label {
    border-color: rgba(255, 255, 255, 0.42);
    color: rgba(255, 255, 255, 0.7);
  }
}

#filepond:not(.disabled),
#filepond label {
  cursor: pointer;
}

.file-error {
  .filepond--wrapper {
    .filepond--drop-label {
      border: 2px solid var(--v-error-base);
    }
  }
}
</style>
