<template>
  <v-app-bar app color="white" :height="xsOnly ? 150 : 75" class="app-bar elevation-1">
    <div class="d-flex flex-grow-1 align-center justify-space-between flex-column flex-sm-row">
      <v-img alt="logo" :src="logoSrc" max-width="140" contain />
      <div class="d-flex align-center justify-center flex-wrap mt-7 mt-sm-0">
        <a v-if="balance" href="#" class="black--text mt-3 mr-3" @click.prevent="goToFinance">
          {{ balance.amount }} {{ balance.currency }}
        </a>
        <LanguageSelect class="mt-3 mr-3" />
        <CustomSwitch
          v-model="currentThemes"
          :inset="false"
          class="mt-3 mr-7 mr-md-9 theme-switch"
          @change="switchThemes(currentThemes)"
        />
        <!--         TODO: при нажатии на справку открывать справа диалог где будут все ссылка на все разделы этой страниц (Главная - детали - создание и тд)-->
        <div class="mt-3 mr-15">
          <v-icon @click="openFaq">mdi-help-circle-outline</v-icon>
        </div>
        <div class="mt-3 mr-15">
          <v-menu
            v-model="isOpenNotification"
            :close-on-content-click="false"
            allow-overflow
            :min-width="xsOnly ? 310 : 460"
            offset-y
            nudge-bottom="20"
            nudge-left="250"
          >
            <template #activator="{on}">
              <v-icon @click="openNotification" v-on="on">mdi-bell-outline</v-icon>
              <v-fade-transition leave-absolute>
                <v-badge color="red" class="pl-4 pb-5" dot :value="isNotification" />
              </v-fade-transition>
            </template>
            <div ref="notification" class="custom-virtual-scroll__container" :style="{maxHeight: '333px'}">
              <v-list v-if="isNotEmptyNotification">
                <template v-for="(message, i) in messageItems">
                  <v-list-item :key="message.id" class="mb-5">
                    <div class="d-flex justify-space-between align-center flex-grow-1 pt-3">
                      <div class="mr-10">
                        <router-link v-if="message.route" :to="checkRouteData(message)">
                          {{ message.message }}
                        </router-link>
                        <div v-else>{{ message.message }}</div>
                        <div class="greyDark--text">{{ message.text }}</div>
                      </div>
                      <div>
                        <v-icon
                          v-if="showActionIcon(message, DOWNLOAD_ACTION)"
                          color="red"
                          class="mr-5"
                          @click="download(message)"
                        >
                          mdi-download
                        </v-icon>
                        <v-icon color="red" @click="deleteMessage(message)">
                          mdi-close-circle-outline
                        </v-icon>
                      </div>
                    </div>
                  </v-list-item>
                  <v-divider v-if="i < messageItems.length - 1" :key="'divider_' + i" />
                  <div v-if="i === messageItems.length - 1" :key="'observer_' + i.id">
                    <Observer v-if="!loading && !dataPagination.last" @loadMore="loadMore" />
                    <Loader v-if="loading && !dataPagination.last" />
                  </div>
                </template>
              </v-list>
              <v-list v-else>
                <div v-if="loading" class="text-center">
                  <Loader />
                </div>
                <v-list-item v-else>{{ $t('notification.empty') }}</v-list-item>
              </v-list>
            </div>
          </v-menu>
        </div>
        <v-menu
          :close-on-content-click="isClose"
          allow-overflow
          :min-width="xsOnly ? 310 : 360"
          offset-y
          nudge-bottom="20"
          nudge-left="250"
        >
          <template #activator="{on}">
            <IconButton class="mt-3" v-on="on" @click="openMenu">
              <v-icon>mdi-account</v-icon>
            </IconButton>
          </template>
          <v-list class="app-bar__profile-list">
            <v-list-item to="/profile" class="my-2" @click="closeMenu">
              <v-icon>mdi-account-box</v-icon>
              <span class="pl-10 pr-5 font-weight-bold">{{ $t('menu.profile') }}</span>
              <span v-if="userInfo">{{ userInfo.login }}</span>
            </v-list-item>
            <v-list-item v-if="isShowClient" class="my-2">
              <div>
                <div class="mb-3">
                  <span class="font-weight-bold pr-5">{{ $t('title.email') }}:</span> {{ userInfo.email }}
                </div>
                <div class="mb-3">
                  <span class="font-weight-bold pr-5">{{ $t('title.telegram') }}:</span> {{ manager.tg }}
                </div>
                <div class="mb-3">
                  <span class="font-weight-bold pr-5">{{ $t('title.manager') }}:</span> {{ manager.email }}
                </div>
              </div>
            </v-list-item>
            <v-list-item class="my-2" @click="logoutHandler">
              <span>{{ $t('button.logout') }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {setItem} from '@/helpers/persistantStorage'
import {DOWNLOAD_ACTION} from '@/helpers/variables/type'

export default {
  name: 'AppBar',
  inheritAttrs: false,
  data: () => ({
    currentThemes: false,
    isClose: false,
    isOpenNotification: false,
    messageItems: [],
    DOWNLOAD_ACTION,
  }),
  mounted() {
    if (this.isShowClient) this.loadBalance()
    this.currentThemes = this.$vuetify.theme.isDark
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
      balance: (state) => state.security.balance,
      params: (state) => state.notification.params,
      items: (state) => state.notification.items,
      loading: (state) => state.notification.loading,
      dataPagination: (state) => state.notification.dataPagination,
      isNotification: (state) => state.notification.isNotification,
      message: (state) => state.notification.message,
    }),
    ...mapGetters({
      manager: 'auth/manager',
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      isAdvertiser: 'auth/isAdvertiser',
      isAdvertiserPro: 'auth/isAdvertiserPro',
      isAdvertiserManager: 'auth/isAdvertiserManager',
    }),
    isShowClient() {
      return this.isAdvertiser || this.isAdvertiserPro || this.isAdvertiserManager
    },
    logoSrc() {
      return this.$vuetify.theme.dark
        ? require('@/assets/images/nt_logo_white.png')
        : require('@/assets/images/nt_logo_blue.png')
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isNotEmptyNotification() {
      return this.messageItems.length
    },
  },
  watch: {
    isOpenNotification(value) {
      if (!value) {
        setTimeout(() => {
          this.messageItems = []
        }, 300)
      }
    },
    $route() {
      this.isOpenNotification = false
    },
    // message(message) {
    //   if (message) this.messageItems.unshift(message)
    // },
  },
  methods: {
    ...mapActions({
      logout: 'auth/LOGOUT',
      getBalance: 'security/GET_BALANCE',
      getNotifications: 'notification/GET_ITEMS',
      deleteNotificationById: 'notification/DELETE_ITEM',
      downloadFile: 'file/DOWNLOAD_FILE',
    }),
    ...mapMutations({
      setPage: 'notification/SET_PAGE_NUMBER',
      setNotification: 'notification/SET_IS_NOTIFICATION',
    }),
    async loadBalance() {
      await this.getBalance()
    },
    switchThemes(switches) {
      this.$vuetify.theme.dark = switches
      setItem('nt_theme', switches ? 'dark' : 'light')
    },
    logoutHandler() {
      this.logout()
      this.$router.push({name: 'Login'})
    },
    openMenu() {
      if (this.isClose) this.isClose = false
    },
    closeMenu() {
      this.isClose = true
    },
    openNotification() {
      if (!this.isOpenNotification) this.loadNotification(true)
    },
    async loadNotification(isStart = false) {
      this.setPage(isStart)
      await this.getNotifications({params: this.params, isStart})
      this.messageItems = this.items.map((item) => {
        const {id, messageCode, data, date, time, route} = item
        const routeType = data?.type?.toLowerCase()
        let message = this.$t(`notification.${messageCode}`, {
          name: data?.name,
          type: data?.type || data?.user,
        })
        if (data?.amount && data?.currency) {
          message = `${message} (${data.amount} ${data.currency})`
        }

        return {
          id,
          message,
          text: `(${date}, ${time})`,
          route: route
            ? {
                ...route,
                type: routeType,
                name: this.setRouteName(route.name),
              }
            : null,
          data,
        }
      })
      if (this.isNotification) this.setNotification(false)
    },
    setRouteName(route) {
      let name = ''
      switch (true) {
        case this.isAdmin:
          name = 'Admin'
          break
        case this.isManager:
          name = 'Manager'
          break
        case this.isAdvertiser:
          name = 'Advertiser'
          break
        default:
          name = ''
          break
      }
      return `${name}${route}`
    },
    showActionIcon(message, type) {
      return message?.data?.action === type
    },
    checkRouteData(item) {
      const {route} = item
      return {
        name: route?.name,
        params: {id: route?.id, type: route?.type, tab: route?.tab},
      }
    },
    async deleteMessage(message) {
      await this.deleteNotificationById(message.id)
      this.messageItems = this.messageItems.filter((a) => a.id !== message.id)
    },
    async download(message) {
      const {data} = message
      await this.downloadFile(data.url)
    },
    loadMore() {
      if (this.loading || this.dataPagination.last) return
      this.loadNotification()
    },
    goToFinance() {
      const path = {name: 'AdvertiserReplenishFinance'}
      if (this.$route.name !== path.name) this.$router.push(path)
    },
    openFaq() {
      this.$emit('action-faq')
    },
  },
}
</script>
<style lang="scss">
.app-bar {
  &.v-app-bar.v-app-bar--fixed {
    z-index: 15;
  }

  &__profile-list {
    .v-list-item--active {
      color: var(--v-blue-base);

      &:before {
        opacity: 0;
      }
    }
  }

  .theme-switch {
    &.v-input--switch {
      .v-input--switch__thumb {
        &:before {
          content: '';
          width: 85%;
          height: 85%;
        }

        &.theme--light {
          &:before {
            background: url(~@/assets/images/icon/white-balance-sunny.svg) no-repeat;
          }
        }

        &.theme--dark {
          &:before {
            background: url(~@/assets/images/icon/moon-waning-crescent.svg) no-repeat;
            transform: rotate(-30deg);
          }
        }
      }
    }
  }
}
</style>
