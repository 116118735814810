<template>
  <v-chip
    class="my-2"
    :label="label"
    :close="close"
    :color="color"
    :text-color="textColor"
    :dark="dark"
    :close-icon="closeIcon"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-chip>
</template>

<script>
export default {
  name: 'CustomChip',
  inheritAttrs: false,
  props: {
    label: {
      type: Boolean,
      default: true,
    },
    close: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'white',
    },
    textColor: {
      type: String,
      default: 'black',
    },
    closeIcon: {
      type: String,
      default: 'mdi-close',
    },
  },
}
</script>
