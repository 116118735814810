export const ROLE = {
  ADMIN: 'ROLE_ADMIN',
  MANAGER: 'ROLE_MANAGER',
  ADVERTISER: 'ROLE_ADVERTISER',
  ADVERTISER_PRO: 'ROLE_ADVERTISER_PRO',
  ADVERTISER_MANAGER: 'ROLE_ADVERTISER_MANAGER',
}

export const roles = [
  {
    text: 'ADMIN',
    value: ROLE.ADMIN,
  },
  {
    text: 'MANAGER',
    value: ROLE.MANAGER,
  },
  {
    text: 'ADVERTISER',
    value: ROLE.ADVERTISER,
  },
  {
    text: 'ADVERTISER_PRO',
    value: ROLE.ADVERTISER_PRO,
  },
  {
    text: 'ADVERTISER_MANAGER',
    value: ROLE.ADVERTISER_MANAGER,
  },
]

export const getRoles = (array) => {
  return roles.filter((item) => {
    for (const key in array) {
      if (item.value === undefined || item.value === array[key]) return false
    }
    return true
  })
}
