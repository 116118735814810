import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'
import {getItem, setItem} from '@/helpers/persistantStorage'

Vue.use(Vuetify)

let theme = 'light'
if (getItem('nt_theme')) {
  theme = getItem('nt_theme')
} else {
  setItem('nt_theme', theme)
}

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: theme === 'dark',
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        blue: '#0028ff',
        grey: '#ccd8ed',
        greyChip: '#ccd8ed',
        greyDark: '#616b76',
        greyLight: '#dedede',
        'greyLight-2': '#f4f4f4',
        'greyLight-3': '#b9b9b9',
        red: '#ee1d52',
        green: '#42cf7b',
        black: '#000018',
        white: '#ffffff',
        mainBg: '#f2f5f8',
        activeLink: '#0028ff',
        tableColor: '#111111',
        darkDefault: '#ccd8ed',
        'column-1': '#c7c7c7',
        'column-2': '#ccd8ed',
      },
      dark: {
        blue: '#ccd8ed',
        grey: '#ccd8ed',
        greyDark: '#616b76',
        greyChip: '#616b76',
        greyLight: '#dedede',
        'greyLight-2': '#2b2b2b',
        'greyLight-3': '#b9b9b9',
        red: '#ee1d52',
        green: '#42cf7b',
        black: '#f5f5f5',
        white: '#272727',
        mainBg: '#121212',
        activeLink: '#679cf9',
        tableColor: '#ffffff',
        darkDefault: '#1e1e1e',
        'column-1': '#656363',
        'column-2': '#585757',
      },
    },
    breakpoint: {
      thresholds: {
        xs: 0,
        sp: 365,
        sm: 600,
        md: 1024,
        lg: 1280,
        xl: 1440,
      },
    },
  },
})
