<template>
  <TooltipEffect top :message="tooltipText">
    <template #activator="{on}">
      <v-hover v-slot="{hover}">
        <v-btn small icon :disabled="disabled" :class="className" v-on="on">
          <v-icon :color="hover ? color : defaultColor" :size="size" v-bind="$attrs" v-on="$listeners">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-hover>
    </template>
  </TooltipEffect>
</template>

<script>
export default {
  name: 'TooltipIconButton',
  props: {
    size: {
      type: [Number, String],
      default: 18,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'red',
    },
    defaultColor: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
}
</script>
