import {getItems} from '@/store/main'
import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import {currencySymbols} from '@/helpers/variables/currencies'
import {v4 as uuidv4} from 'uuid'
import {fileDownload} from '@/helpers/fileDownload'
import i18n from '@/i18n/i18n'
import qs from 'qs'
import {STATISTIC_COLUMNS} from '@/helpers/permissions/common'

const TYPE_LOADING = 'ITEMS'
const TYPE_LOADING_ACTION = 'ACTION'

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  sort: 'date,desc',
  isClientsStatistics: undefined,
}

const state = () => ({
  items: [],
  groups: [],
  loading: false,
  actionLoading: false,
  dataPagination: {},
  params: {...DEFAULT_PARAMS},
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      read: rootState.auth?.permissions?.some((a) => a === 'STATISTIC_READ'),
      readAll: rootState.auth?.permissions?.some((a) => a === 'STATISTIC_READ_ALL'),
      viewColumns: rootState.auth?.permissions?.filter((a) => STATISTIC_COLUMNS.some((b) => a === b)),
    }
  },
}

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items.map((a) => {
      const currency = currencySymbols[a.currency] ?? a.currency
      return {
        ...a,
        uuid: uuidv4(),
        statSpend: `${a.statSpend} ${currency}`,
        cpc: `${a.cpc} ${currency}`,
        cpm: `${a.cpm} ${currency}`,
        cpv: `${a.cpv} ${currency}`,
        vtr: `${a.vtr} %`,
        completionRate: `${a.completionRate} %`,
        fillRate: `${a.fillRate} %`,
      }
    })
  },
  SET_STATISTIC_GROUPS(state, groups) {
    state.groups = groups
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_PARAMS(state, params) {
    state.params = {
      ...state.params,
      ...params,
    }
  },
  DELETE_PARAM(state, param) {
    delete state.params[param.name]
  },
  SET_PAGE_NUMBER(state, {page}) {
    state.params = {
      ...state.params,
      page: page - 1,
    }
  },
  SET_SORT_PARAMS(state, {sortNameParam, sortSetParam}) {
    const sortStr = sortNameParam && sortSetParam ? `${sortNameParam},${sortSetParam}` : DEFAULT_PARAMS.sort
    state.params = {
      ...state.params,
      sort: sortStr,
    }
  },
  SET_DATES(state, dates) {
    state.params = {
      ...state.params,
      dateFrom: dates[0],
      dateTo: dates[1],
    }
  },
  SET_CLIENTS_STATISTICS(state, value = DEFAULT_PARAMS.isClientsStatistics) {
    state.params.isClientsStatistics = value
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING:
        state.loading = loading
        break
      case TYPE_LOADING_ACTION:
        state.actionLoading = loading
        break
    }
  },
  RESET_PARAMS(state, isResetAll = true) {
    const dateFrom = isResetAll ? undefined : state.params.dateFrom
    const dateTo = isResetAll ? undefined : state.params.dateTo
    const groupParams = isResetAll ? undefined : state.params.groupParams
    const sort = isResetAll ? DEFAULT_PARAMS.sort : state.params.sort
    state.params = {...DEFAULT_PARAMS, dateFrom, dateTo, groupParams, sort}
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: true})
    await getItems(commit, dispatch, TYPE_LOADING, 'statistic/user/all', params)
  },
  async GET_STATISTIC_GROUPS({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
    try {
      const body = {isClientsStatistics: params.isClientsStatistics}
      const res = await axios.get('dictionary/statistic/grouping-params/all', {params: body})
      if (res.status === 200) {
        commit('SET_STATISTIC_GROUPS', res.data)
      }
    } catch (err) {
      await customErrors(err, dispatch)
      commit('SET_STATISTIC_GROUPS', [])
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
    }
  },
  async EXPORT_FILE({commit, dispatch}, params) {
    try {
      const res = await axios.get('file/statistic/user-statistic-report/view', {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: 'repeat',
            encode: false,
            skipNulls: true,
          })
        },
      })
      const {data, status} = res
      if (data && status === 200) {
        await fileDownload(res)
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.fileDownload'), {
          root: true,
        })
        return data
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
