<template>
  <v-combobox
    v-model="currentValue"
    v-bind="$attrs"
    :items="items"
    :outlined="outlined"
    :dense="dense"
    :clearable="clearable"
    :multiple="multiple"
    :disabled="disabled"
    :small-chips="smallChips"
    :deletable-chips="deletableChips"
    :append-icon="appendIcon"
    required
    :no-data-text="$t('service.listIsEmpty')"
    :search-input.sync="search"
    :label="labelValue"
    :rules="rules"
    class="custom-combobox"
    v-on="$listeners"
  >
    <template #no-data>
      <div class="py-2 px-5">
        <p>
          <span class="subheading pr-2">{{ $t('combobox.create') }}:</span>
          "<strong>{{ search }}</strong
          >"
        </p>
        <p>{{ $t('combobox.press') }} <kbd>enter</kbd> {{ $t('combobox.text') }}</p>
      </div>
    </template>

    <template #selection="{item, index, attrs, selected, select}">
      <div class="selection-block pt-2 pb-1 pr-2">
        <template v-if="withOtherLine">
          <v-chip v-if="index === 0">{{ item }}</v-chip>
          <span v-if="index === 1" class="red--text text-caption">
            (+{{ currentValue.length - 1 }} {{ $t('label.others') }})
          </span>
        </template>
        <template v-else>
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            close
            @click="select"
            @click:close="$emit('remove', item)"
          >
            {{ item }}
          </v-chip>
        </template>
      </div>
    </template>

    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: 'CustomCombobox',
  inheritAttrs: true,
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    smallChips: {
      type: Boolean,
      default: true,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    withOtherLine: {
      type: Boolean,
      default: true,
    },
    appendIcon: {
      type: String,
      default: 'mdi-chevron-down',
    },
    label: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    search: null,
  }),
  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    labelValue() {
      return this.rules.length ? this.label + ' *' : this.label
    },
  },
}
</script>
<style lang="scss">
.custom-combobox {
  .selection-block {
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
