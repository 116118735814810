<template>
  <div v-if="isTimer">
    {{ $t(text) }} <span>0:{{ checkSeconds }}</span>
  </div>
</template>
<script>
export default {
  name: 'BlockTimer',
  props: {
    isStart: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'service.authAgain',
    },
  },
  data() {
    return {
      seconds: 59,
      secondsTimer: null,
      isTimer: false,
    }
  },
  computed: {
    checkSeconds() {
      return this.seconds < 10 ? `0${this.seconds}` : `${this.seconds}`
    },
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    timer() {
      this.secondsTimer = setInterval(() => {
        if (this.seconds < 1) this.stopTimer()
        else {
          this.seconds -= 1
        }
      }, 1000)
    },
    stopTimer() {
      clearInterval(this.secondsTimer)
      this.seconds = 59
      this.isTimer = false
      this.$emit('stop')
    },
    startTimer() {
      this.timer()
      this.isTimer = true
    },
  },
}
</script>
