<template>
  <TooltipIconButton
    icon="mdi-file-upload"
    :tooltipText="text"
    :color="color"
    :disabled="disabled"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'UploadButton',
  props: {
    color: {
      type: String,
      default: 'red',
    },
    text: {
      type: String,
      default: 'tooltip.invoice',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
