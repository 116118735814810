import i18n from '@/i18n/i18n'

const customErrors = async (err, dispatch, view = 'SHOW_TOAST_ERROR', message) => {
  const {response: {data = {}} = {}} = err

  const messageItems = {
    BAD_CREDENTIALS: 'toast.authCredentials',
    EMAIL_IN_USE: 'toast.emailUse',
    PHONE_IN_USE: 'toast.phoneUse',
    CONTRACT_NUMBER_IN_USE: 'toast.contractNumberUse',
    CREATE_TARGET_ERROR: 'toast.createTargetError',
    NO_MODERATED_CREATIVES: 'toast.noModeratedCreatives',
    FILE_NOT_FOUND: 'toast.fileNotFound',
    USER_CONTRACT_NOT_FOUND: 'toast.fileNotFound',
    EXCHANGE_RATE_SAME_CURRENCY: 'toast.exchangeRateSame',
    EXCHANGE_RATE_EXIST: 'toast.exchangeRateExist',
    CAMPAIGN_END_DATE_PAST_ERROR: 'toast.campaignEndDatePast',
    VAT_EXIST: 'toast.financeVatExist',
    LOGIN_IN_USE: 'toast.loginInUse',
    PUBLIC_ZONE_INFO_NOT_FOUND: 'toast.infoNotFound',
    NOT_POSSIBLE_UPDATE_VAST_DAAST_CREATIVE: 'toast.notPossibleUpdateCreative',
    PASSWORDS_DO_NOT_MATCH: 'toast.passwordsDoNotMatch',
    CURRENT_PASSWORD_IS_WRONG: 'toast.currentPasswordIsWrong',
    RESET_PASSWORD_TOKEN_EXPIRED_ERROR: 'toast.expiredPasswordToken',
  }

  const frontError = messageItems[data?.code]

  if (frontError) {
    dispatch(view, i18n.t(frontError), {root: true})
  } else {
    dispatch(view, i18n.t(message) || i18n.t('toast.error') || data.message, {root: true})
  }
}

export default customErrors
