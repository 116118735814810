<template>
  <v-pagination
    v-if="totalPages > 1 && isShow"
    v-model="number"
    v-bind="$attrs"
    class="text-center py-6"
    :length="totalPages"
    :page="number"
    total-visible="10"
    :disabled="disabled"
    color="red"
  />
</template>

<script>
export default {
  name: 'Pagination',
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    number: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
