import {getItems, deleteItem, getItemById, createItem, editItem} from '@/store/main'
import axios from '@/plugins/axios'
import customErrors from '@/store/error'

const TYPE_LOADING = 'INTEGRATION'
const TYPE_LOADING_ACTION = 'ACTION'

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  sort: 'id,desc',
}

const state = () => ({
  items: [],
  item: null,
  loading: false,
  actionLoading: false,
  dataPagination: {},
  params: {...DEFAULT_PARAMS},
  sspSpecificItems: [],
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      read: rootState.auth?.permissions?.some((a) => a === 'INTEGRATION_READ'),
      readAll: rootState.auth?.permissions?.some((a) => a === 'INTEGRATION_READ_ALL'),
      new: rootState.auth.permissions?.some((a) => a === 'INTEGRATION_CREATE'),
      update: rootState.auth?.permissions?.some((a) => a === 'INTEGRATION_UPDATE'),
      remove: rootState.auth?.permissions?.some((a) => a === 'INTEGRATION_REMOVE'),
    }
  },
}

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
  },
  SET_ITEM_BY_ID(state, item) {
    state.item = item
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_PARAMS(state, params) {
    state.params = {
      ...state.params,
      ...params,
    }
  },
  DELETE_PARAM(state, param) {
    delete state.params[param.name]
  },
  SET_PAGE_NUMBER(state, {page}) {
    state.params = {
      ...state.params,
      page: page - 1,
    }
  },
  SET_SORT_PARAMS(state, {sortNameParam, sortSetParam}) {
    const sortStr = sortNameParam && sortSetParam ? `${sortNameParam},${sortSetParam}` : DEFAULT_PARAMS.sort
    state.params = {
      ...state.params,
      sort: sortStr,
    }
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING:
        state.loading = loading
        break
      case TYPE_LOADING_ACTION:
        state.actionLoading = loading
        break
    }
  },
  RESET_PARAMS(state) {
    state.params = {...DEFAULT_PARAMS}
  },
  SET_SSP_SPECIFIC_ITEMS(state, items) {
    state.sspSpecificItems = items
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: true})
    commit('SET_ITEM_BY_ID', null)
    await getItems(commit, dispatch, TYPE_LOADING, 'integration/all', params)
  },
  async GET_ITEM_BY_ID({commit, dispatch}, id) {
    await getItemById(commit, dispatch, TYPE_LOADING, `integration/view/${id}`)
  },
  async CREATE_INTEGRATION({commit, dispatch}, params) {
    return await createItem(commit, dispatch, TYPE_LOADING_ACTION, 'integration/create', params)
  },
  async EDIT_INTEGRATION({commit, dispatch}, {id, formData}) {
    return await editItem(commit, dispatch, TYPE_LOADING_ACTION, `integration/update/${id}`, formData)
  },
  async DELETE_INTEGRATION({commit, dispatch}, id) {
    return await deleteItem(commit, dispatch, TYPE_LOADING_ACTION, `integration/delete/${id}`)
  },
  async GET_SSP_SPECIFIC_ITEMS({commit, dispatch}) {
    try {
      const res = await axios.get('dictionary/integration/ssp-specific-processing-key/read-all')
      const {data, status} = res
      if (status === 200) {
        commit(
          'SET_SSP_SPECIFIC_ITEMS',
          data.map((a) => ({
            text: a,
            value: a,
          })),
        )
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
