<template>
  <div>
    <LeftDrawer />
    <AppBar @action-faq="actionFaq" />
    <v-main>
      <v-container fluid class="px-8 px-sm-10 pt-15 pb-20">
        <router-view />
      </v-container>
    </v-main>
    <WikiDrawer v-model="isShow" @close="actionFaq" />
  </div>
</template>

<script>
import LeftDrawer from '@/components/navigations/LeftDrawer'
import AppBar from '@/components/navigations/AppBar'
import WikiDrawer from '@/components/navigations/WikiDrawer.vue'

export default {
  main: 'MainLayout',
  components: {LeftDrawer, AppBar, WikiDrawer},
  data: () => ({
    isShow: false,
  }),
  methods: {
    actionFaq() {
      this.isShow = !this.isShow
    },
  },
}
</script>
