import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import i18n from '@/i18n/i18n'
import {createItem, editItem, getItemById, getItems} from '@/store/main'

const TYPE_LOADING_PROFILE = 'PROFILE'
const TYPE_LOADING_SECURITY = 'USER'
const TYPE_LOADING_SECURITY_ACTION = 'ACTION'

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  sort: 'id,desc',
}

const state = () => ({
  items: [],
  item: null,
  balance: null,
  profile: null,
  profileLoading: false,
  loading: false,
  actionLoading: false,
  dataPagination: {},
  params: {...DEFAULT_PARAMS},
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      read: rootState.auth?.permissions?.some((a) => a === 'SECURITY_READ'),
      readAll: rootState.auth?.permissions?.some((a) => a === 'SECURITY_READ_ALL'),
      new: rootState.auth.permissions?.some((a) => a === 'SECURITY_CREATE'),
      update: rootState.auth?.permissions?.some((a) => a === 'SECURITY_UPDATE'),
      chooseRole: rootState.auth?.permissions?.some((a) => a === 'SECURITY_CREATE_SELECT_ROLE'),
      choosePermissions: rootState.auth?.permissions?.some((a) => a === 'SECURITY_CREATE_SELECT_PERMISSIONS'),
    }
  },
}

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
  },
  SET_ITEM_BY_ID(state, item) {
    state.item = item
  },
  SET_BALANCE(state, balance) {
    state.balance = balance
  },
  SET_PROFILE(state, data) {
    state.profile = {...data}
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_PARAMS(state, params) {
    state.params = {
      ...state.params,
      ...params,
    }
  },
  DELETE_PARAM(state, param) {
    delete state.params[param.name]
  },
  SET_PAGE_NUMBER(state, {page}) {
    state.params = {
      ...state.params,
      page: page - 1,
    }
  },
  SET_SORT_PARAMS(state, {sortNameParam, sortSetParam}) {
    const sortStr = sortNameParam && sortSetParam ? `${sortNameParam},${sortSetParam}` : DEFAULT_PARAMS.sort
    state.params = {
      ...state.params,
      sort: sortStr,
    }
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING_PROFILE:
        state.profileLoading = loading
        break
      case TYPE_LOADING_SECURITY:
        state.loading = loading
        break
      case TYPE_LOADING_SECURITY_ACTION:
        state.actionLoading = loading
        break
    }
  },
  RESET_PARAMS(state) {
    state.params = {...DEFAULT_PARAMS}
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_LOADING_SECURITY, loading: true})
    commit('SET_ITEM_BY_ID', null)
    await getItems(commit, dispatch, TYPE_LOADING_SECURITY, 'user/all', params)
  },
  async GET_ITEM_BY_ID({commit, dispatch}, id) {
    await getItemById(commit, dispatch, TYPE_LOADING_SECURITY, `user/view/${id}`)
  },
  async CREATE_USER({commit, dispatch}, formData) {
    return await createItem(
      commit,
      dispatch,
      TYPE_LOADING_SECURITY_ACTION,
      'user/create',
      formData,
      'toast.successUserCreate',
    )
  },
  async EDIT_USER({commit, dispatch}, formData) {
    return await editItem(
      commit,
      dispatch,
      TYPE_LOADING_SECURITY_ACTION,
      `user/update/${formData.id}`,
      formData,
      'toast.successUserEdit',
    )
  },
  async GET_BALANCE({commit, dispatch}) {
    try {
      const res = await axios.get('user/balance')
      if (res.status === 200) {
        commit('SET_BALANCE', res.data)
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async GET_PROFILE({commit, dispatch}) {
    commit('SET_LOADING', {type: TYPE_LOADING_PROFILE, loading: true})
    try {
      const res = await axios.get('user/profile')
      if (res.status === 200) {
        commit('SET_PROFILE', res.data)
      }
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_PROFILE, loading: false})
    }
  },
  async UPDATE_PROFILE({commit, dispatch}, formData) {
    commit('SET_LOADING', {type: TYPE_LOADING_PROFILE, loading: true})
    try {
      const res = await axios.put('user/profile/update', formData)
      if (res.status === 200) {
        commit('SET_PROFILE', formData)
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.profileChanged'), {root: true})
      }
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_PROFILE, loading: false})
    }
  },
  async CHECK_LOGIN_IN_USE({commit, dispatch}, login) {
    try {
      const res = await axios.post('user/login-in-use', {login})
      if (res.status === 200) {
        if (res.data) {
          dispatch('SHOW_TOAST_ERROR', i18n.t('toast.loginExists'), {root: true})
        }
        return res.data
      }
    } catch (err) {
      await customErrors(err, dispatch)
      return false
    }
  },
  async UPDATE_PASSWORD({commit, dispatch}, formData) {
    try {
      const res = await axios.put('user/profile/update/password', formData)
      if (res.status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.successPassword'), {root: true})
      }
      return res
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
