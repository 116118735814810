import {ROLE} from '@/helpers/variables/roles'
const roles = [ROLE.ADVERTISER, ROLE.ADVERTISER_PRO, ROLE.ADVERTISER_MANAGER]

export default [
  {
    path: '/advertiser/dashboard',
    name: 'AdvertiserDashboard',
    meta: {layout: 'main', auth: true, role: roles, permission: 'DASHBOARD_'},
    component: () => import(/* webpackChunkName: "advertiser-dashboard" */ '@/views/dashboard/Dashboard'),
  },
  {
    path: '/advertiser/clients',
    name: 'AdvertiserClient',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () => import(/* webpackChunkName: "advertiser-clients" */ '@/views/clients/Clients'),
  },
  {
    path: '/advertiser/client/detail/:id',
    name: 'AdvertiserClientDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-client-detail" */ '@/views/clients/ClientDetail'),
  },
  {
    path: '/advertiser/client/payment/:id',
    name: 'AdvertiserClientPayment',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-client-payment" */ '@/views/clients/ClientPayment.vue'),
  },
  {
    path: '/advertiser/client/new',
    name: 'AdvertiserNewClient',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-client-new" */ '@/views/security/SecurityNew.vue'),
  },
  {
    path: '/advertiser/statistics',
    name: 'AdvertiserStatistic',
    meta: {layout: 'main', auth: true, role: roles, permission: 'STATISTIC_'},
    component: () => import(/* webpackChunkName: "advertiser-statistics" */ '@/views/statistics/Statistics'),
  },
  {
    path: '/advertiser/campaigns',
    name: 'AdvertiserCampaign',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () => import(/* webpackChunkName: "advertiser-campaigns" */ '@/views/campaigns/Campaigns'),
  },
  {
    path: '/advertiser/campaign/new',
    name: 'AdvertiserNewCampaign',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-campaign-new" */ '@/views/campaigns/CampaignDetail'),
  },
  {
    path: '/advertiser/campaign/detail/:id',
    name: 'AdvertiserCampaignDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-campaign-detail" */ '@/views/campaigns/CampaignDetail'),
  },
  {
    path: '/advertiser/campaign/group/:id',
    name: 'AdvertiserCampaignCreativesGroup',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () =>
      import(
        /* webpackChunkName: "advertiser-campaign-creatives-group" */ '@/views/campaigns/CampaignCreativesGroup'
      ),
  },
  {
    path: '/advertiser/creatives',
    name: 'AdvertiserCreative',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CREATIVE_'},
    component: () => import(/* webpackChunkName: "advertiser-creatives" */ '@/views/creatives/Creatives'),
  },
  {
    path: '/advertiser/creative/detail/:type/:id',
    name: 'AdvertiserCreativeDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CREATIVE_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-creative-detail" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/advertiser/creative/:type/new',
    name: 'AdvertiserNewCreative',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CREATIVE_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-creative-new" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/advertiser/targets',
    name: 'AdvertiserTarget',
    meta: {layout: 'main', auth: true, role: roles, permission: 'TARGET_'},
    component: () => import(/* webpackChunkName: "advertiser-targets" */ '@/views/targets/Targets'),
  },
  {
    path: '/advertiser/target/detail/:id',
    name: 'AdvertiserTargetDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'TARGET_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-target-detail" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/advertiser/target/new',
    name: 'AdvertiserNewTarget',
    meta: {layout: 'main', auth: true, role: roles, permission: 'TARGET_'},
    component: () => import(/* webpackChunkName: "advertiser-target-new" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/advertiser/rules',
    name: 'AdvertiserRule',
    meta: {layout: 'main', auth: true, role: roles, permission: 'RULE_'},
    component: () => import(/* webpackChunkName: "advertiser-rules" */ '@/views/rules/Rules'),
  },
  {
    path: '/advertiser/rule/detail/:type/:id',
    name: 'AdvertiserRuleDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'RULE_'},
    component: () => import(/* webpackChunkName: "advertiser-rule-detail" */ '@/views/rules/RuleDetail'),
  },
  {
    path: '/advertiser/rule/:type/new',
    name: 'AdvertiserNewRule',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'RULE_'},
    component: () => import(/* webpackChunkName: "advertiser-rule-new" */ '@/views/rules/RuleDetail'),
  },
  {
    path: '/advertiser/segments',
    name: 'AdvertiserSegment',
    meta: {layout: 'main', auth: true, role: roles, permission: 'SEGMENT_'},
    component: () => import(/* webpackChunkName: "advertiser-segments" */ '@/views/segments/Segments'),
  },
  {
    path: '/advertiser/documents',
    name: 'AdvertiserDocs',
    meta: {layout: 'main', auth: true, role: roles, permission: 'DOCS_'},
    component: () => import(/* webpackChunkName: "advertiser-doc" */ '@/views/doc/Doc'),
  },
  {
    path: '/advertiser/finances',
    name: 'AdvertiserFinances',
    meta: {layout: 'main', auth: true, role: roles, permission: 'FINANCES_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-finances" */ '@/views/finance/AdvertiserFinance.vue'),
  },
  {
    path: '/advertiser/finance/balance',
    name: 'AdvertiserReplenishFinance',
    meta: {layout: 'main', auth: true, role: roles, permission: 'FINANCES_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-balance-finance" */ '@/views/finance/ReplenishFinance.vue'),
  },
  {
    path: '/advertiser/security',
    name: 'AdvertiserSecurity',
    meta: {layout: 'main', auth: true, role: roles, permission: 'SECURITY_'},
    component: () => import(/* webpackChunkName: "advertiser-security" */ '@/views/security/Security.vue'),
  },
  {
    path: '/advertiser/security/detail/:id',
    name: 'AdvertiserSecurityDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-security-detail" */ '@/views/security/SecurityDetail.vue'),
  },
  {
    path: '/advertiser/security/new',
    name: 'AdvertiserNewSecurity',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () =>
      import(/* webpackChunkName: "advertiser-security-new" */ '@/views/security/SecurityNew.vue'),
  },
]
