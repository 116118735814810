<template>
  <v-checkbox
    v-model="checkbox"
    v-bind="$attrs"
    :hideDetails="hideDetails"
    :color="color"
    :label="labelValue"
    :rules="rules"
    on-icon="mdi-checkbox-marked"
    dense
    v-on="$listeners"
  >
    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  inheritAttrs: false,
  props: {
    value: {
      type: [Boolean, String],
      default: false,
    },
    label: {
      type: [String, Number],
      default: '',
    },
    color: {
      type: String,
      default: 'blue',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    checkbox: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    labelValue() {
      return this.rules.length ? this.$t(this.label) + ' *' : this.$t(this.label)
    },
  },
}
</script>

<style lang="scss">
.v-input.v-input--selection-controls.v-input--checkbox {
  & > .v-input__control > .v-input__slot > .v-input--selection-controls__input {
    margin-right: 12px;

    & > i.v-icon {
      font-size: 20px;
    }
  }

  &.v-input--is-label-active {
    .v-input__slot {
      label:after {
        left: 0;
        right: auto;
        width: 100%;
      }
    }
  }
}
</style>
