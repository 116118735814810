import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import {deleteItem} from '@/store/main'
import i18n from '@/i18n/i18n'

const TYPE_LOADING = 'ITEMS'
const TYPE_LOADING_ACTION = 'ACTION'

const DEFAULT_PARAMS = {
  page: 0,
  size: 5,
  sort: 'date&time,desc',
}

let stompClient = null

const state = () => ({
  items: [],
  loading: false,
  actionLoading: false,
  params: {...DEFAULT_PARAMS},
  dataPagination: {},
  message: null,
  isNotification: false,
})

const mutations = {
  SET_ITEMS(state, {items, isStart}) {
    if (isStart) {
      state.items = items
    } else {
      state.items = [...state.items, ...items]
    }
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = data
  },
  SET_PAGE_NUMBER(state, isStart = false) {
    if (isStart) {
      state.params = DEFAULT_PARAMS
    } else {
      state.params = {
        ...DEFAULT_PARAMS,
        page: state.params.page + 1,
      }
    }
  },
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING:
        state.loading = loading
        break
      case TYPE_LOADING_ACTION:
        state.actionLoading = loading
        break
    }
  },
  SET_MESSAGE(state, message) {
    state.message = message
  },
  SET_IS_NOTIFICATION(state, payload) {
    state.isNotification = payload
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, {params, isStart}) {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: true})
    try {
      const res = await axios.get('notification/all', {
        params,
      })
      if (res.status === 200) {
        const {content, number, last, totalPages, totalElements} = res.data
        commit('SET_ITEMS', {items: content, isStart})
        commit('SET_PAGINATION', {
          number,
          last,
          totalPages,
          totalElements,
        })
      }
    } catch (err) {
      await customErrors(err, dispatch)
      commit('SET_ITEMS', [])
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING, loading: false})
    }
  },
  async DELETE_ITEM({commit, dispatch}, id) {
    return await deleteItem(commit, dispatch, TYPE_LOADING_ACTION, `notification/delete/${id}`)
  },
  async CONNECT_WEBSOCKET({commit, dispatch}, {csrfToken, accessToken, login}) {
    const headers = {
      'X-CSRF-TOKEN': csrfToken,
      Authorization: accessToken,
    }
    const urlWs = `${process.env.VUE_APP_API_URL}/ws-notifications`
    const urlTopic = `/topic/notifications/${login}`
    const socket = new SockJS(urlWs)
    stompClient = Stomp.over(socket)
    stompClient.debug = () => {}
    stompClient.connect(
      headers,
      (frame) => {
        stompClient.subscribe(urlTopic, (tick) => {
          const {data, date, id, messageCode, route, time} = JSON.parse(tick.body)
          const message = {data, date, id, messageCode, route, time}
          commit('SET_MESSAGE', message)
          commit('SET_IS_NOTIFICATION', true)
          if (messageCode === 'USER_BALANCE_REPLENISHED') {
            let message = i18n.t(`notification.${messageCode}`)
            if (data?.amount && data?.currency) {
              message = `${message} (${data.amount} ${data.currency})`
            }
            dispatch('SHOW_TOAST_INFO', message, {
              root: true,
            })
          }
        })
      },
      (error) => {
        console.error(error, 'CONNECT_WEBSOCKET')
      },
    )
  },
  DISCONNECT_WEBSOCKET() {
    if (stompClient) {
      stompClient.disconnect()
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
