<template>
  <div ref="trigger"></div>
</template>
<script>
export default {
  name: 'Observer',
  data: () => ({
    observer: null,
    options: {
      root: null,
      threshold: 0.1,
    },
  }),
  methods: {
    callback(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) this.$emit('loadMore')
      })
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.callback, this.options)
    this.observer.observe(this.$refs.trigger)
  },
  destroyed() {
    this.observer.disconnect()
  },
}
</script>
