import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import qs from 'qs'
import i18n from '@/i18n/i18n'

export const getItems = async (commit, dispatch, TYPE_LOADING, url, params) => {
  try {
    const res = await axios.get(url, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, {
          arrayFormat: 'repeat',
          encode: false,
          skipNulls: true,
        })
      },
    })
    if (res.status === 200) {
      const {content} = res.data
      commit('SET_ITEMS', content)
      commit('SET_PAGINATION', res.data)
    }
  } catch (err) {
    await customErrors(err, dispatch)
    commit('SET_ITEMS', [])
  } finally {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: false})
  }
}

export const getItemById = async (commit, dispatch, TYPE_LOADING, url) => {
  commit('SET_LOADING', {type: TYPE_LOADING, loading: true})
  try {
    const res = await axios.get(url)
    if (res.status === 200) {
      commit('SET_ITEM_BY_ID', res.data)
    }
  } catch (err) {
    await customErrors(err, dispatch)
    commit('SET_ITEM_BY_ID', null)
  } finally {
    commit('SET_LOADING', {type: TYPE_LOADING, loading: false})
  }
}

export const createItem = async (
  commit,
  dispatch,
  TYPE_LOADING_ACTION,
  url,
  params,
  toast = 'toast.successCreate',
) => {
  commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
  try {
    const res = await axios.post(url, params)
    if (res.status === 200) {
      dispatch('SHOW_TOAST_SUCCESS', i18n.t(toast), {
        root: true,
      })
      return res
    }
  } catch (err) {
    await customErrors(err, dispatch)
  } finally {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
  }
}

export const editItem = async (
  commit,
  dispatch,
  TYPE_LOADING_ACTION,
  url,
  params,
  toast = 'toast.successEdit',
) => {
  commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
  try {
    const res = await axios.put(url, params)
    if (res.status === 200) {
      dispatch('SHOW_TOAST_SUCCESS', i18n.t(toast), {
        root: true,
      })
      return res
    }
  } catch (err) {
    await customErrors(err, dispatch)
  } finally {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
  }
}

export const deleteItem = async (
  commit,
  dispatch,
  TYPE_LOADING_ACTION,
  url,
  toast = 'toast.successDeleted',
) => {
  commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
  try {
    const res = await axios.delete(url)
    if (res.status === 200) {
      dispatch('SHOW_TOAST_SUCCESS', i18n.t(toast), {
        root: true,
      })
    }
  } catch (err) {
    await customErrors(err, dispatch)
  } finally {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
  }
}

export const archivedItem = async (
  commit,
  dispatch,
  TYPE_LOADING_ACTION,
  url,
  toast = 'toast.successDefault',
) => {
  commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
  try {
    const res = await axios.put(url)
    if (res.status === 200) {
      dispatch('SHOW_TOAST_SUCCESS', i18n.t(toast), {
        root: true,
      })
    }
  } catch (err) {
    await customErrors(err, dispatch)
  } finally {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
  }
}

export const actionItem = async (dispatch, url, toast = 'toast.successDefault', data) => {
  try {
    const res = await axios.post(url, data)
    if (res.status === 200) {
      dispatch('SHOW_TOAST_SUCCESS', i18n.t(toast), {
        root: true,
      })
      return true
    }
  } catch (err) {
    await customErrors(err, dispatch)
  }
}
