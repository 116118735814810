<template>
  <v-snackbar
    v-model="show"
    v-bind="$attrs"
    transition="slide-x-reverse-transition"
    timeout="6000"
    app
    right
    bottom
    elevation="1"
    v-on="$listeners"
  >
    <span class="font-16">{{ message }}</span>

    <template #action="{attrs}">
      <v-btn text v-bind="attrs" @click="show = false">
        <span>{{ $t('button.close') }}</span>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'CustomToaster',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    message: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.closeToast()
        this.$emit('input', value)
      },
    },
  },
  methods: {
    ...mapActions({
      closeToast: 'CLOSE_TOAST',
    }),
  },
}
</script>
