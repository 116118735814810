<template>
  <TooltipIconButton
    :icon="icon"
    tooltipText="tooltip.details"
    :color="color"
    :disabled="disabled"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'DetailButton',
  props: {
    icon: {
      type: String,
      default: 'mdi-chevron-right',
    },
    color: {
      type: String,
      default: 'red',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
