import CryptoJS from 'crypto-js'
import i18n from '@/i18n/i18n'

export const encrypt = (permissions) => {
  try {
    return CryptoJS.AES.encrypt(
      permissions,
      CryptoJS.enc.Base64.parse(process.env.VUE_APP_PERMISSIONS_SECRET_KEY),
      {
        iv: CryptoJS.enc.Base64.parse(process.env.VUE_APP_PERMISSIONS_SECRET_VECTOR),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    ).ciphertext.toString(CryptoJS.enc.Base64)
  } catch (error) {
    console.error(i18n.t('toast.encryptError'), error)
    return null
  }
}

export const decrypt = (permissions) => {
  try {
    return JSON.parse(
      CryptoJS.AES.decrypt(
        permissions,
        CryptoJS.enc.Base64.parse(process.env.VUE_APP_PERMISSIONS_SECRET_KEY),
        {
          iv: CryptoJS.enc.Base64.parse(process.env.VUE_APP_PERMISSIONS_SECRET_VECTOR),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        },
      ).toString(CryptoJS.enc.Utf8),
    )
  } catch (error) {
    console.error(i18n.t('toast.decryptError'), error)
    return null
  }
}
