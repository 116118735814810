import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import i18n from '@/i18n/i18n'
import {fileDownload} from '@/helpers/fileDownload'

const state = () => ({
  targetFiles: {},
  loading: false,
})

const getters = {}

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_TARGET_FILES(state, {type, files}) {
    state.targetFiles[type] = files
  },
}

const actions = {
  async PARSE_FILE_TEXT({commit, dispatch}, {type, formData}) {
    commit('SET_LOADING', true)
    try {
      const res = await axios.post('file/parse/text', formData)
      const {data, status} = res
      if (data && status === 200) {
        commit('SET_TARGET_FILES', {type, files: data})
      }
    } catch (err) {
      await customErrors(err, dispatch)
      commit('SET_TARGET_FILES', {type, files: []})
    }
    commit('SET_LOADING', false)
  },
  async UPLOAD_FILE({commit, dispatch}, {url, formData}) {
    try {
      const res = await axios.post(url, formData)
      const {data, status} = res
      if (data && status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.fileUpload'), {
          root: true,
        })
        return data
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async DOWNLOAD_FILE({commit, dispatch}, url) {
    try {
      const res = await axios.get(url, {
        responseType: 'blob',
      })
      const {data, status} = res
      if (data && status === 200) {
        await fileDownload(res)
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.fileDownload'), {
          root: true,
        })
        return data
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async REPLACE_FILE({commit, dispatch}, {url, formData}) {
    try {
      const res = await axios.put(url, formData)
      const {data, status} = res
      if (data && status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.fileReplaced'), {
          root: true,
        })
        return data
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async DELETE_FILE({commit, dispatch}, url) {
    try {
      const res = await axios.delete(url)
      const {data, status} = res
      if (data && status === 200) {
        return data
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
