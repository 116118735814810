import Vue from 'vue'
import VueI18n from 'vue-i18n'

import {getItem} from '@/helpers/persistantStorage'
import english from './english.json'
import russian from './russian.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: getItem('nt_system_language') !== null ? getItem('nt_system_language') : 'en', // set default locale
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: {
    en: english,
    ru: russian,
  },
})
