import {getItems} from '@/store/main'

const TYPE_FOR_ITEMS = 'ITEMS'

const DEFAULT_PARAMS = {
  page: 0,
  size: 20,
  sort: 'id,desc',
}

const state = () => ({
  items: [],
  loading: false,
  params: {...DEFAULT_PARAMS},
  dataPagination: {},
})

const mutations = {
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_FOR_ITEMS:
        state.loading = loading
        break
    }
  },
  SET_ITEMS(state, items) {
    state.items = items
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_PAGE_NUMBER(state, {page}) {
    state.params = {
      ...state.params,
      page: page - 1,
    }
  },
  SET_PARAMS(state, params) {
    state.params = {
      ...state.params,
      ...params,
    }
  },
  DELETE_PARAM(state, param) {
    delete state.params[param.name]
  },
  RESET_PARAMS(state) {
    state.params = {...DEFAULT_PARAMS}
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, {url, params}) {
    commit('SET_LOADING', {type: TYPE_FOR_ITEMS, loading: true})
    commit('SET_ITEMS', [])
    await getItems(commit, dispatch, TYPE_FOR_ITEMS, url, params)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
