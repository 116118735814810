export const CAMPAIGN_COLUMNS = [
  'CAMPAIGN_STATISTICS_COLUMN_ID',
  'CAMPAIGN_STATISTICS_COLUMN_BIDDER_STATUS',
  'CAMPAIGN_STATISTICS_COLUMN_STATUS',
  'CAMPAIGN_STATISTICS_COLUMN_NAME',
  'CAMPAIGN_STATISTICS_COLUMN_USER_ID',
  'CAMPAIGN_STATISTICS_COLUMN_CAMPAIGN_TYPE',
  'CAMPAIGN_STATISTICS_COLUMN_STRATEGY',
  'CAMPAIGN_STATISTICS_COLUMN_DAILY_BUDGET',
  'CAMPAIGN_STATISTICS_COLUMN_TOTAL_BUDGET',
  'CAMPAIGN_STATISTICS_COLUMN_STAT_CLICKS',
  'CAMPAIGN_STATISTICS_COLUMN_STAT_IMPRESSIONS',
  'CAMPAIGN_STATISTICS_COLUMN_CTR',
  'CAMPAIGN_STATISTICS_COLUMN_STAT_SPEND',
  'CAMPAIGN_STATISTICS_COLUMN_CPC',
  'CAMPAIGN_STATISTICS_COLUMN_CPM',
  'CAMPAIGN_STATISTICS_COLUMN_CPV',
  'CAMPAIGN_STATISTICS_COLUMN_VTR',
  'CAMPAIGN_STATISTICS_COLUMN_COMPLETION_RATE',
  'CAMPAIGN_STATISTICS_COLUMN_FILL_RATE',
  'CAMPAIGN_STATISTICS_COLUMN_STAT_BIDS',
  'CAMPAIGN_STATISTICS_COLUMN_STAT_WINS',
  'CAMPAIGN_STATISTICS_COLUMN_WIN_RATE',
]
export const STATISTIC_COLUMNS = [
  'STATISTIC_COLUMN_COMPLETION_RATE',
  'STATISTIC_COLUMN_CPC',
  'STATISTIC_COLUMN_CPM',
  'STATISTIC_COLUMN_CPV',
  'STATISTIC_COLUMN_CTR',
  'STATISTIC_COLUMN_STAT_CLICKS',
  'STATISTIC_COLUMN_STAT_IMPRESSIONS',
  'STATISTIC_COLUMN_STAT_SPEND',
  'STATISTIC_COLUMN_VTR',
]
export const TARGET_PANELS = [
  'TARGET_ADD_ADVANCED',
  'TARGET_ADD_ADVANCED_VERIFICATION_SERVICES',
  'TARGET_ADD_CARRIER',
  'TARGET_ADD_CONNECTION_TYPE',
  'TARGET_ADD_DEVICE_TYPE',
  'TARGET_ADD_EXCLUDED',
  'TARGET_ADD_IAB',
  'TARGET_ADD_MAKE',
  'TARGET_ADD_PAGE',
  'TARGET_ADD_PLACEMENT',
]
