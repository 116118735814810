import { render, staticRenderFns } from "./WikiDrawer.vue?vue&type=template&id=7aeb8571&scoped=true"
import script from "./WikiDrawer.vue?vue&type=script&lang=js"
export * from "./WikiDrawer.vue?vue&type=script&lang=js"
import style0 from "./WikiDrawer.vue?vue&type=style&index=0&id=7aeb8571&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aeb8571",
  null
  
)

export default component.exports