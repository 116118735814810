const convertObjToArray = (obj, isEqually = false, withId = false) => {
  return Object.keys(obj).map((key) => {
    const text = withId ? `${key} - ${obj[key]}` : obj[key]
    const value = isEqually ? obj[key] : +key
    return {
      text,
      value,
    }
  })
}

export default convertObjToArray
