import {ROLE} from '@/helpers/variables/roles'

const roles = [ROLE.MANAGER]

export default [
  {
    path: '/manager/clients',
    name: 'ManagerClient',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () => import(/* webpackChunkName: "manager-clients" */ '@/views/clients/Clients'),
  },
  {
    path: '/manager/client/detail/:id',
    name: 'ManagerClientDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () => import(/* webpackChunkName: "manager-client-detail" */ '@/views/clients/ClientDetail'),
  },
  {
    path: '/manager/client/payment/:id',
    name: 'ManagerClientPayment',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () =>
      import(/* webpackChunkName: "manager-client-payment" */ '@/views/clients/ClientPayment.vue'),
  },
  {
    path: '/manager/client/new',
    name: 'ManagerNewClient',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () => import(/* webpackChunkName: "manager-client-new" */ '@/views/security/SecurityNew.vue'),
  },
  {
    path: '/manager/dashboard',
    name: 'ManagerDashboard',
    meta: {layout: 'main', auth: true, role: roles, permission: 'DASHBOARD_'},
    component: () => import(/* webpackChunkName: "manager-dashboard" */ '@/views/dashboard/Dashboard'),
  },
  {
    path: '/manager/statistics',
    name: 'ManagerStatistic',
    meta: {layout: 'main', auth: true, role: roles, permission: 'STATISTIC_'},
    component: () => import(/* webpackChunkName: "manager-statistics" */ '@/views/statistics/Statistics'),
  },
  {
    path: '/manager/campaigns',
    name: 'ManagerCampaign',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () => import(/* webpackChunkName: "manager-campaigns" */ '@/views/campaigns/Campaigns'),
  },
  {
    path: '/manager/campaign/new',
    name: 'ManagerNewCampaign',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () =>
      import(/* webpackChunkName: "manager-campaign-new" */ '@/views/campaigns/CampaignDetail'),
  },
  {
    path: '/manager/campaign/detail/:id',
    name: 'ManagerCampaignDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () =>
      import(/* webpackChunkName: "manager-campaign-detail" */ '@/views/campaigns/CampaignDetail'),
  },
  {
    path: '/manager/campaign/group:id',
    name: 'ManagerCampaignCreativesGroup',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CAMPAIGN_'},
    component: () =>
      import(
        /* webpackChunkName: "manager-campaign-creatives-group" */ '@/views/campaigns/CampaignCreativesGroup'
      ),
  },
  {
    path: '/manager/creatives',
    name: 'ManagerCreative',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CREATIVE_'},
    component: () => import(/* webpackChunkName: "manager-creatives" */ '@/views/creatives/Creatives'),
  },
  {
    path: '/manager/creative/detail/:type/:id',
    name: 'ManagerCreativeDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CREATIVE_'},
    component: () =>
      import(/* webpackChunkName: "manager-creative-detail" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/manager/creative/:type/new',
    name: 'ManagerNewCreative',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CREATIVE_'},
    component: () =>
      import(/* webpackChunkName: "manager-creative-new" */ '@/views/creatives/CreativeDetail'),
  },
  {
    path: '/manager/targets',
    name: 'ManagerTarget',
    meta: {layout: 'main', auth: true, role: roles, permission: 'TARGET_'},
    component: () => import(/* webpackChunkName: "manager-targets" */ '@/views/targets/Targets'),
  },
  {
    path: '/manager/target/detail/:id',
    name: 'ManagerTargetDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'TARGET_'},
    component: () => import(/* webpackChunkName: "manager-target-detail" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/manager/target/new',
    name: 'ManagerNewTarget',
    meta: {layout: 'main', auth: true, role: roles, permission: 'TARGET_'},
    component: () => import(/* webpackChunkName: "manager-target-new" */ '@/views/targets/TargetDetail'),
  },
  {
    path: '/manager/segments',
    name: 'ManagerSegment',
    meta: {layout: 'main', auth: true, role: roles, permission: 'SEGMENT_'},
    component: () => import(/* webpackChunkName: "manager-segments" */ '@/views/segments/Segments'),
  },
  {
    path: '/manager/rules',
    name: 'ManagerRule',
    meta: {layout: 'main', auth: true, role: roles, permission: 'RULE_'},
    component: () => import(/* webpackChunkName: "manager-rules" */ '@/views/rules/Rules'),
  },
  {
    path: '/manager/rule/detail/:type/:id',
    name: 'ManagerRuleDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'RULE_'},
    component: () => import(/* webpackChunkName: "manager-rule-detail" */ '@/views/rules/RuleDetail'),
  },
  {
    path: '/manager/rule/:type/new',
    name: 'ManagerNewRule',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'RULE_'},
    component: () => import(/* webpackChunkName: "manager-rule-new" */ '@/views/rules/RuleDetail'),
  },
  {
    path: '/manager/documents',
    name: 'ManagerDocs',
    meta: {layout: 'main', auth: true, role: roles, permission: 'DOCS_'},
    component: () => import(/* webpackChunkName: "manager-doc" */ '@/views/doc/Doc'),
  },
  {
    path: '/manager/tags',
    name: 'ManagerTag',
    meta: {layout: 'main', auth: true, role: roles, permission: 'TAG_'},
    component: () => import(/* webpackChunkName: "manager-tags" */ '@/views/tags/Tags'),
  },
  {
    path: '/manager/finances',
    name: 'ManagerFinances',
    meta: {layout: 'main', auth: true, role: roles, permission: 'FINANCES_'},
    component: () => import(/* webpackChunkName: "manager-finances" */ '@/views/finance/Finance.vue'),
  },
  {
    path: '/manager/security',
    name: 'ManagerSecurity',
    meta: {layout: 'main', auth: true, role: roles, permission: 'SECURITY_'},
    component: () => import(/* webpackChunkName: "manager-security" */ '@/views/security/Security.vue'),
  },
  {
    path: '/manager/security/detail/:id',
    name: 'ManagerSecurityDetail',
    props: true,
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () =>
      import(/* webpackChunkName: "manager-security-detail" */ '@/views/security/SecurityDetail.vue'),
  },
  {
    path: '/manager/security/new',
    name: 'ManagerNewSecurity',
    meta: {layout: 'main', auth: true, role: roles, permission: 'CLIENT_'},
    component: () =>
      import(/* webpackChunkName: "manager-security-new" */ '@/views/security/SecurityNew.vue'),
  },
]
