const managerRoutes = {
  dashboard: {
    order: 1,
    route: {name: 'ManagerDashboard'},
  },
  client: {
    order: 2,
    route: {name: 'ManagerClient'},
  },
  creative: {
    order: 3,
    route: {name: 'ManagerCreative'},
  },
  target: {
    order: 4,
    route: {name: 'ManagerTarget'},
  },
  campaign: {
    order: 5,
    route: {name: 'ManagerCampaign'},
  },
  statistic: {
    order: 6,
    route: {name: 'ManagerStatistic'},
  },
  segment: {
    order: 7,
    route: {name: 'ManagerSegment'},
  },
  rule: {
    order: 8,
    route: {name: 'ManagerRule'},
  },
  tag: {
    order: 9,
    route: {name: 'ManagerTag'},
  },
  finance: {
    order: 10,
    route: {name: 'ManagerFinances'},
  },
  security: {
    order: 11,
    route: {name: 'ManagerSecurity'},
  },
  doc: {
    order: 12,
    route: {name: 'ManagerDocs'},
  },
}

export default managerRoutes
