<template>
  <v-autocomplete
    v-model="currentValue"
    v-bind="$attrs"
    :items="items"
    :outlined="outlined"
    :dense="dense"
    :clearable="clearable"
    :multiple="multiple"
    :disabled="disabled"
    :small-chips="smallChips"
    :deletable-chips="deletableChips"
    :append-icon="appendIcon"
    item-text="text"
    item-value="value"
    required
    :no-data-text="$t('service.listIsEmpty')"
    :label="labelValue"
    :rules="rules"
    :return-object="returnObject"
    item-color="blue darken-1"
    v-on="$listeners"
  >
    <!--    <template #item="{item, attrs, on}">-->
    <!--      <v-list-item #default="{active}" v-bind="attrs" v-on="on">-->
    <!--        <v-list-item-action>-->
    <!--          <v-checkbox :input-value="active" />-->
    <!--        </v-list-item-action>-->
    <!--        <v-list-item-content>-->
    <!--          <v-list-item-title>-->
    <!--            {{ item.text }}-->
    <!--          </v-list-item-title>-->
    <!--        </v-list-item-content>-->
    <!--      </v-list-item>-->
    <!--    </template>-->
    <!--    <template #selection="{item, index, attrs, selected, select}">-->
    <!--      <div v-if="withOtherLine" class="py-2">-->
    <!--        <v-chip v-if="index === 0" small>{{ $t(item.text) }}</v-chip>-->
    <!--        <span v-if="index === 1" class="red&#45;&#45;text text-caption">-->
    <!--          (+{{ currentValue.length - 1 }} {{ $t('label.others') }})-->
    <!--        </span>-->
    <!--      </div>-->
    <!--      <div v-else class="pt-2 pb-1">-->
    <!--        <v-chip-->
    <!--          v-bind="attrs"-->
    <!--          :input-value="selected"-->
    <!--          close-->
    <!--          small-->
    <!--          @click="select"-->
    <!--          @click:close="$emit('remove', item.value)"-->
    <!--        >-->
    <!--          {{ $t(item.text) }}-->
    <!--        </v-chip>-->
    <!--      </div>-->
    <!--    </template>-->

    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'CustomAutocomplete',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number, Object, Array],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: true,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    withOtherLine: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: 'mdi-chevron-down',
    },
    label: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    labelValue() {
      return this.rules.length ? this.label + ' *' : this.label
    },
  },
}
</script>
