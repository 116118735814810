import axiosInstance from '@/plugins/axios'
import {getItem} from '@/helpers/persistantStorage'

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getItem('nt_token')
      if (token) {
        config.headers['Authorization'] = token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  const refreshAndRetryQueue = []
  let isRefreshing = false

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config

      if (error.response && error.response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true
          try {
            const newAccessToken = await store.dispatch('auth/REFRESH_TOKEN')

            error.config.headers['Authorization'] = `${newAccessToken}`

            // Retry all requests in the queue with the new token
            refreshAndRetryQueue.forEach(({config, resolve, reject}) => {
              axiosInstance
                .request(config)
                .then((response) => resolve(response))
                .catch((err) => reject(err))
            })

            // Clear the queue
            refreshAndRetryQueue.length = 0

            // Retry the original request
            return axiosInstance(originalRequest)
          } catch (refreshError) {
            // You can clear all storage and redirect the user to the login page
            return Promise.reject(refreshError)
          } finally {
            isRefreshing = false
          }
        }

        // Add the original request to the queue
        return new Promise((resolve, reject) => {
          refreshAndRetryQueue.push({config: originalRequest, resolve, reject})
        })
      }

      return Promise.reject(error)
    },
  )
}

export default setup
