import Vue from 'vue'
import App from './App.vue'
import i18n from '@/i18n/i18n'
import router from './router'
import {v4 as uuidv4} from 'uuid'
import * as VueGoogleMaps from 'vue2-google-maps'

import Maska from 'maska'
import Vuelidate from 'vuelidate'

import './scss/app.scss'

import CustomToaster from '@/components/notification/CustomToaster'
import CustomInput from '@/components/formElements/CustomInput'
import CustomSelect from '@/components/formElements/CustomSelect'
import CustomCheckbox from '@/components/formElements/CustomCheckbox'
import CustomSwitch from '@/components/switch/CustomSwitch'
import CustomCombobox from '@/components/formElements/CustomCombobox'
import CustomAutocomplete from '@/components/formElements/CustomAutocomplete'
import CustomAutocompleteWithTranslated from '@/components/formElements/CustomAutocompleteWithTranslated.vue'
import LanguageSelect from '@/components/select/LanguageSelect'
import ActionButton from '@/components/buttons/ActionButton'
import IconButton from '@/components/buttons/IconButton'
import CopyButton from '@/components/buttons/CopyButton'
import TooltipIconButton from '@/components/buttons/TooltipIconButton.vue'
import DetailButton from '@/components/buttons/tooltip/DetailButton.vue'
import ArchiveButton from '@/components/buttons/tooltip/ArchiveButton.vue'
import DeleteButton from '@/components/buttons/tooltip/DeleteButton.vue'
import DownloadButton from '@/components/buttons/tooltip/DownloadButton.vue'
import UpdateButton from '@/components/buttons/tooltip/UpdateButton.vue'
import UploadButton from '@/components/buttons/tooltip/UploadButton.vue'
import EditButton from '@/components/buttons/tooltip/EditButton.vue'
import CreateButton from '@/components/buttons/tooltip/CreateButton.vue'
import BlockTimer from '@/components/block/BlockTimer'
import TooltipEffect from '@/components/tooltip/TooltipEffect'
import CustomTable from '@/components/table/CustomTable'
import Pagination from '@/components/pagination/Pagination'
import CustomChip from '@/components/chip/CustomChip'
import CustomDialog from '@/components/dialog/CustomDialog'
import CustomTableFilter from '@/components/table/CustomTableFilter'
import FileUploader from '@/components/filepond/FileUploader'
import CustomTextarea from '@/components/formElements/CustomTextarea'
import Observer from '@/components/loader/Observer'
import Loader from '@/components/loader/Loader'
import DatePickerWithField from '@/components/datePicker/DatePickerWithField.vue'
import CustomAlert from '@/components/alert/CustomAlert.vue'
import api from '@/plugins/axios'
import vuetify from '@/plugins/vuetify'
import store from './store'
import setupInterceptors from '@/services/setupInterceptors'

Vue.component('CustomToaster', CustomToaster)
Vue.component('CustomInput', CustomInput)
Vue.component('CustomSelect', CustomSelect)
Vue.component('CustomCheckbox', CustomCheckbox)
Vue.component('CustomSwitch', CustomSwitch)
Vue.component('CustomCombobox', CustomCombobox)
Vue.component('CustomAutocomplete', CustomAutocomplete)
Vue.component('CustomAutocompleteWithTranslated', CustomAutocompleteWithTranslated)
Vue.component('LanguageSelect', LanguageSelect)
Vue.component('ActionButton', ActionButton)
Vue.component('IconButton', IconButton)
Vue.component('CopyButton', CopyButton)
Vue.component('TooltipIconButton', TooltipIconButton)
Vue.component('DetailButton', DetailButton)
Vue.component('ArchiveButton', ArchiveButton)
Vue.component('DeleteButton', DeleteButton)
Vue.component('DownloadButton', DownloadButton)
Vue.component('UpdateButton', UpdateButton)
Vue.component('UploadButton', UploadButton)
Vue.component('EditButton', EditButton)
Vue.component('CreateButton', CreateButton)
Vue.component('BlockTimer', BlockTimer)
Vue.component('TooltipEffect', TooltipEffect)
Vue.component('CustomTable', CustomTable)
Vue.component('CustomTableFilter', CustomTableFilter)
Vue.component('Pagination', Pagination)
Vue.component('CustomChip', CustomChip)
Vue.component('CustomDialog', CustomDialog)
Vue.component('FileUploader', FileUploader)
Vue.component('CustomTextarea', CustomTextarea)
Vue.component('Observer', Observer)
Vue.component('Loader', Loader)
Vue.component('DatePickerWithField', DatePickerWithField)
Vue.component('CustomAlert', CustomAlert)

// off ripple vuetify effects
const overrideRipple = {
  directives: {
    ripple: {
      inserted: () => {},
    },
  },
}
Vue.mixin(overrideRipple)

Vue.use(Vuelidate)
Vue.use(Maska)
setupInterceptors(store)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_KEY,
    libraries: 'places',
    language: i18n.locale,
  },
})

Vue.config.productionTip = false
Vue.prototype.$axios = api
Vue.prototype.$uuidv4 = uuidv4

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
