<template>
  <TooltipIconButton
    icon="mdi-pencil"
    tooltipText="tooltip.edit"
    :color="color"
    :disabled="disabled"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'EditButton',
  props: {
    color: {
      type: String,
      default: 'red',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
