import {phoneRegular} from '@/helpers/variables/expression'

const onlyNumbers = (event, withPlus = false) => {
  let pattern = withPlus ? phoneRegular.plusAndNumber : phoneRegular.number
  const regex = new RegExp(pattern)
  const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
  if (!regex.test(key) && isNaN(Number(event.key))) {
    event.preventDefault()
    return false
  }
}

export default onlyNumbers
