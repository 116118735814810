<template>
  <v-select
    v-model="select"
    v-bind="$attrs"
    :items="items"
    :outlined="outlined"
    :dense="dense"
    :solo="solo"
    :flat="flat"
    :rounded="rounded"
    :disabled="disabled"
    :readonly="readonly"
    required
    :multiple="multiple"
    :append-icon="appendIcon"
    item-text="text"
    item-value="value"
    :no-data-text="$t('service.listIsEmpty')"
    :return-object="returnObject"
    :label="labelValue"
    :rules="rules"
    @change="change"
  >
    <template #selection="{item, index}">
      <div v-if="multiple">
        <span v-if="index === 0">
          {{ $t(item.text) }}
        </span>
        <span v-if="index === 1" class="text-caption pl-2">
          (+{{ select.length - 1 }} {{ $t('label.others') }})
        </span>
      </div>
      <div v-else class="v-select__selection custom-select__selection">{{ $t(item.text) }}</div>
    </template>
    <template #item="{item, attrs, on}">
      <v-list-item v-if="multiple" #default="{active}" color="blue darken-1" v-bind="attrs" dense v-on="on">
        <v-list-item-action>
          <v-checkbox color="blue darken-1" dense :ripple="false" :input-value="active" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t(item.text) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item-content v-else>
        <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
      </v-list-item-content>
    </template>

    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CustomSelect',
  inheritAttrs: true,
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: 'mdi-chevron-down',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    select: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    labelValue() {
      return this.rules.length ? this.label + ' *' : this.label
    },
  },
  methods: {
    change(value) {
      this.$emit('change', value)
    },
    // checkTranslateValue(item) {
    //   return this.$te(item.text) ? this.$t(item.text) : item.text
    // },
  },
}
</script>
<style lang="scss">
.columns-select {
  max-width: 300px;
}

.custom-select__selection {
  min-height: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
