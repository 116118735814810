import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import {createItem, deleteItem, editItem, getItemById, getItems} from '@/store/main'
import convertIabToArray from '@/helpers/convertIabToArray'

const TYPE_FOR_ITEMS = 'ITEMS'
const TYPE_LOADING_ACTION = 'ACTION'
const TYPE_IAB_CATEGORY = 'IAB'

const DEFAULT_FORM_DATA = {
  page: 0,
  size: 12,
  sort: 'createdAt,desc',
}

const DEFAULT_CATEGORY_PARAMS = {
  page: 0,
  size: 50,
}

const state = () => ({
  items: [],
  item: null,
  loading: false,
  actionLoading: false,
  formData: {...DEFAULT_FORM_DATA},
  dataPagination: {},
  isResetSortParams: false,
  categoryItems: [],
  categoryLoading: false,
  categoryPagination: {},
  categoryParams: DEFAULT_CATEGORY_PARAMS,
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      read: rootState.auth?.permissions?.some((a) => a === 'CREATIVE_READ'),
      readAll: rootState.auth?.permissions?.some((a) => a === 'CREATIVE_READ_ALL'),
      new: rootState.auth.permissions?.some((a) => a === 'CREATIVE_CREATE'),
      newForClient: rootState.auth.permissions?.some((a) => a === 'CREATIVE_CREATE_FOR_CLIENT'),
      update: rootState.auth?.permissions?.some((a) => a === 'CREATIVE_UPDATE'),
      moderate: rootState.auth?.permissions?.some((a) => a === 'CREATIVE_MODERATE'),
      remove: rootState.auth?.permissions?.some((a) => a === 'CREATIVE_REMOVE'),
      addMarkup: rootState.auth?.permissions?.some((a) => a === 'CREATIVE_ADD_VAST_DAAST'),
      addHTML5: rootState.auth?.permissions?.some((a) => a === 'CREATIVE_ADD_HTML5_BANNER'),
      addJS: rootState.auth?.permissions?.some((a) => a === 'CREATIVE_ADD_JS_BANNER'),
    }
  },
}

const mutations = {
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_FOR_ITEMS:
        state.loading = loading
        break
      case TYPE_LOADING_ACTION:
        state.actionLoading = loading
        break
      case TYPE_IAB_CATEGORY:
        state.categoryLoading = loading
        break
    }
  },
  SET_ITEMS(state, items) {
    state.items = items
  },
  SET_ITEM_BY_ID(state, item) {
    state.item = item
  },
  SET_PAGINATION(state, data) {
    state.dataPagination = {
      pageNumber: data.number + 1,
      totalPages: data.totalPages,
    }
  },
  SET_PAGE_NUMBER(state, page = DEFAULT_FORM_DATA.page) {
    state.formData = {
      ...state.formData,
      page: page - 1,
    }
  },
  SET_SORT_PARAMS(state, {sort}) {
    state.isResetSortParams = false
    state.formData = {
      ...state.formData,
      sort: sort ?? DEFAULT_FORM_DATA.sort,
    }
  },
  RESET_FORM_DATA(state) {
    state.formData = {...DEFAULT_FORM_DATA}
    state.isResetSortParams = true
  },
  SET_IAB_CATEGORY_ITEMS(state, {items, isStart}) {
    if (isStart) {
      state.categoryItems = convertIabToArray(items)
    } else {
      state.categoryItems = [...state.categoryItems, ...convertIabToArray(items)]
    }
  },
  SET_IAB_CATEGORY_PAGINATION(state, data) {
    state.categoryPagination = data
  },
  SET_IAB_CATEGORY_PAGE(state, isStart = false) {
    if (isStart) {
      state.categoryParams = DEFAULT_CATEGORY_PARAMS
    } else {
      state.categoryParams = {
        ...DEFAULT_CATEGORY_PARAMS,
        page: state.categoryParams.page + 1,
      }
    }
  },
}

const actions = {
  async GET_ITEMS({commit, dispatch}, params) {
    commit('SET_LOADING', {type: TYPE_FOR_ITEMS, loading: true})
    commit('SET_ITEMS', [])
    commit('SET_ITEM_BY_ID', null)
    await getItems(commit, dispatch, TYPE_FOR_ITEMS, 'creative/all', params)
  },
  async GET_ITEM_BY_ID({commit, dispatch}, id) {
    await getItemById(commit, dispatch, TYPE_FOR_ITEMS, `creative/view/${id}`)
  },
  async DELETE_CREATIVE({commit, dispatch}, id) {
    return await deleteItem(commit, dispatch, TYPE_LOADING_ACTION, `creative/remove/${id}`)
  },
  async CREATE_CREATIVE({commit, dispatch}, formData) {
    const {userId, ...params} = formData
    const url = userId ? `creative/create/${userId}` : 'creative/create'
    return await createItem(commit, dispatch, TYPE_LOADING_ACTION, url, params)
  },
  async EDIT_CREATIVE({commit, dispatch}, {id, formData}) {
    return await editItem(commit, dispatch, TYPE_LOADING_ACTION, `creative/update/${id}`, formData)
  },
  async MODERATE_CREATIVE({commit, dispatch}, {id, params}) {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
    try {
      const res = await axios.put(`creative/moderate/${id}`, params)
      if (res.status === 200) {
        return res
      }
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
    }
  },
  async GET_IAB_CATEGORY_ITEMS({commit, dispatch}, {params, isStart}) {
    commit('SET_LOADING', {type: TYPE_IAB_CATEGORY, loading: true})
    try {
      const res = await axios.get('dictionary/creative/iab-categories', {params})
      if (res.status === 200) {
        const {content, number, last, totalPages, totalElements} = res.data
        commit('SET_IAB_CATEGORY_ITEMS', {items: content, isStart})
        commit('SET_IAB_CATEGORY_PAGINATION', {
          number,
          last,
          totalPages,
          totalElements,
        })
      }
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_IAB_CATEGORY, loading: false})
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
