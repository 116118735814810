import i18n from '@/i18n/i18n'
export const translateAndSortTypeObj = (data, localName, isEqually = false) => {
  return Object.keys(data)
    .map((key) => {
      const value = isEqually ? data[key] : key
      return {
        text: `${localName}.${value}`,
        value,
      }
    })
    .sort((a, b) => a.value.localeCompare(b.value))
}

export const translateTypeArray = (items, name) => {
  return items.map((a) => {
    const {value, text} = a
    const translatedText = i18n.te(`${name}.${value}`) ? i18n.t(`${name}.${value}`) : text
    return {
      text: translatedText,
      value,
    }
  })
}
