<template>
  <v-alert :type="type" :color="color" :dense="dense" outlined="outlined" :text="text">
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: 'CustomAlert',
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'error',
    },
    color: {
      type: String,
      default: 'red',
    },
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    text: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
