import axios from '@/plugins/axios'
import i18n from '@/i18n/i18n'
import customErrors from '@/store/error'
import {createItem} from '@/store/main'

const TYPE_LOADING_ACTION = 'ACTION'

const state = () => ({
  actionLoading: false,
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      readAll: rootState.auth?.permissions?.some((a) => a === 'EXCHANGE_RATE_READ_ALL'),
      new: rootState.auth.permissions?.some((a) => a === 'EXCHANGE_RATE_CREATE'),
      update: rootState.auth.permissions?.some((a) => a === 'EXCHANGE_RATE_UPDATE'),
    }
  },
}

const mutations = {
  SET_LOADING(state, {type, loading}) {
    switch (type) {
      case TYPE_LOADING_ACTION:
        state.actionLoading = loading
        break
    }
  },
}

const actions = {
  async UPDATE_RATE({commit, dispatch}, {id, params}) {
    commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: true})
    try {
      const res = await axios.put(`exchange-rate/update/${id}`, {...params})
      const {status} = res
      if (status === 200) {
        dispatch('SHOW_TOAST_SUCCESS', i18n.t('toast.courseSuccessfully'), {
          root: true,
        })
        return true
      }
    } catch (err) {
      await customErrors(err, dispatch)
    } finally {
      commit('SET_LOADING', {type: TYPE_LOADING_ACTION, loading: false})
    }
  },
  async GET_CURRENCIES({dispatch}) {
    try {
      const res = await axios.get('dictionary/currency/read-all')
      const {data, status} = res
      if (status === 200) {
        return data.map((a) => ({
          text: a,
          value: a,
        }))
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
  async CREATE_RATE({commit, dispatch}, params) {
    return await createItem(commit, dispatch, TYPE_LOADING_ACTION, 'exchange-rate/create', params)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
