import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import {
  SPEND_TYPE,
  CLICKS_TYPE,
  IMPRESSIONS_TYPE,
  COSTS_TYPE,
  RATES_TYPE,
  CREATIVE_CLICKS_TYPE,
} from '@/helpers/pages/dashboard'

const state = () => ({
  spend: {},
  clicks: {},
  impressions: {},
  costs: {},
  rates: {},
  creativeClicks: {},
})

const getters = {
  permission: (state, getters, rootState) => {
    return {
      readAll: rootState.auth?.permissions?.some((a) => a === 'DASHBOARD_READ_ALL'),
    }
  },
}

const mutations = {
  SET_CHART_DATA(state, {data, type}) {
    state[type] = data
  },
}

const actions = {
  async GET_CHART_DATA({commit, dispatch}, {params, type}) {
    try {
      let url = ''
      switch (type) {
        case SPEND_TYPE:
          url = 'statistic/dashboard/spend'
          break
        case CLICKS_TYPE:
          url = 'statistic/dashboard/clicks'
          break
        case IMPRESSIONS_TYPE:
          url = 'statistic/dashboard/impressions'
          break
        case COSTS_TYPE:
          url = 'statistic/dashboard/cost-per'
          break
        case RATES_TYPE:
          url = 'statistic/dashboard/quality-rate'
          break
        case CREATIVE_CLICKS_TYPE:
          url = 'statistic/dashboard/creative-type-clicks'
          break
      }
      const res = await axios.get(url, {params})
      if (res.status === 200) {
        const {data} = res
        commit('SET_CHART_DATA', {data, type})
      }
    } catch (err) {
      await customErrors(err, dispatch)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
